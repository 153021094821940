@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes knosniGerw {
    0% {
        box-shadow: 0 0 0 0 rgba(var(--dynamic-color), 1); /* Assuming the color is white; replace with desired color */
    }
    40% {
        box-shadow: 0 0 0 6px rgba(var(--dynamic-color), 0);
    }
    60%, 100% {
        box-shadow: 0 0 0 0 rgba(var(--dynamic-color), 0);
    }
}

.pulse {
    -webkit-animation: knosniGerw 5s infinite;
    -moz-animation: knosniGerw 5s infinite;
    -o-animation: knosniGerw 5s infinite;
    -ms-animation: knosniGerw 5s infinite;
    animation: knosniGerw 5s infinite;
}
  

body {
    background-color: #f8f9fa; /* This is a light gray */
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column; /* Stack cards vertically */
    justify-content: center; /* Center cards horizontally */
    align-items: center; /* Start cards at the top, but this can be adjusted if you want to vertically center them */
    padding: 10px; /* Provide some spacing around */
    margin: 0 auto;
}

.card {
    display: block;
    padding: 18px; /* Add inner spacing */
    //margin: 10px 10px; /* Add outer spacing and give 15px margin on left and right */
    border-radius: 6px; /* Slight rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Lifted appearance */
    background-color: #ffffff; /* White background, adjust if necessary */
    max-width: 800px;  /* or whatever maximum width you prefer */
    width: 100%;

}

@media (max-width: 768px) { /* Adjust the breakpoint as needed */
    .card {
        margin-left: 3px; 
        margin-right: 3px;
    }
}

/* Optional: Add a small margin to the top of every card except the first */
.card:not(:first-child) {
    margin-top: 20px;
}

.card-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 16px;
    padding: 2px 0;  /* Vertical padding to give space around the text */
}

.card-body {
    padding-left: 8px;  /* Indent content from the left */
    padding-right: 6px; /* Indent content from the right */
}

*, *::before, *::after {
    box-sizing: border-box;
}
